import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../containers/Hosting/Navbar';
import BannerSectionOther from '../containers/Hosting/Banner/BannerOther/Casestudy';
import InfoOtherSection from '../containers/Hosting/InfoOther/CaseStudy';
import Footer from '../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../components/seo';
import TopRibbon from '../containers/Hosting/TopRibbon';
export default () => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Case Studies"
          description="These Artificial Intelligence Case Studies Show How RAPFlow and RAPBot AI Solutions Helped Clients from Varied Industries Enhance Efficiency & Save Cost."
        />

        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="CaseStudyPage">
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSectionOther />
          <InfoOtherSection />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
