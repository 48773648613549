import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Box from "common/src/components/Box";
import Text from "common/src/components/Text";
import Heading from "common/src/components/Heading";
// import Button from "common/src/components/Button";
import Image from "common/src/components/Image";
import { useStaticQuery, graphql, Link } from "gatsby";

import CasestudyWrapper from "./casestudy.style";

// import sample from "common/src/assets/image/hosting/author-3.jpg";

const CaseStudySection = ({ row, col, title, textArea, description, related, innercol }) => {
  // const Data = useStaticQuery(
  //   graphql`
  // query {
  //   hostingJson {
  //     Case_Study {
  //       title
  //       description
  //       link
  //       avatar {
  //         childImageSharp {
  //           fluid {
  //             ...GatsbyImageSharpFluid
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  //   `
  // );
  const Datas = useStaticQuery(
    graphql`
      query {
        hostingJson {
          Case_studies {
            description
            related
            title
            link
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <CasestudyWrapper>
      <Fade bottom cascade delay={5}>
        {/* <Box {...row}>
          {Data.hostingJson.Case_Study.map((item, index) => (
            <Box {...col} key={index}>
              <Link to={`/case-study/${item.link}`}>
                <Image
                  src={item.avatar.childImageSharp.fluid.src}
                  alt={`image-${index}`}
                />
                <Heading {...title} content={item.title} />
              </Link>
              <Text {...description} content={"Posted"} />
              <Text {...description} content={item.description} />
              <Text
                {...related}
                content={"asd"}
                style={{ textDecoration: "underline" }}
              />
            </Box>
          ))}
        </Box> */}

        <Box {...row}>
          {Datas.hostingJson.Case_studies.map((item, index) => (
            <Box {...col} key={index}>
              <Link to={`/case-study/${item.link}`}>
                <Image src={item.image.childImageSharp.fluid.src} alt={`image-${index}`} />
              </Link>
              <Box {...innercol}>
                <Link to={`/case-study/${item.link}`}>
                  <Heading {...title} content={item.title} />

                  <Text {...description} content={item.description} />
                </Link>
                {item.related.map((x, i) => (
                  <Text key={i} {...(i === 0 ? related : related)} content={x} />
                ))}
                <Link
                  to={`/case-study/${item.link}`}
                  style={{
                    marginLeft: "0px",
                    marginTop: "10px",
                    display: "block",
                    textDecoration: "underline",
                  }}
                >
                  Learn More
                </Link>
              </Box>
            </Box>
          ))}
        </Box>
      </Fade>
    </CasestudyWrapper>
  );
};

CaseStudySection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  textArea: PropTypes.object,
  description: PropTypes.object,
};

CaseStudySection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "center",
    // justifyContent: 'flex-start',
    ml: "-15px",
    mr: "-15px",
  },
  col: {
    width: ["100%", "100%", "47%", "30%", "30%"],
    margin: "2% 1%",
    padding: "0px 0px 30px 0px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    className: "casestudy_contianer",
  },
  innercol: {
    padding: "0px 20px",
  },
  textArea: {
    margin: "20px 15px",
    padding: "30px 15px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    className: "casestudy_contianer",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#0f2137",
    letterSpacing: "0.015em",
    mb: "5px",
    pt: "15px",
    textAlign: "left",
  },
  description: {
    fontSize: "14px",
    color: "#252525",
    mb: "0px",
    pt: "10px",
    pb: "10px",
    textAlign: "left",
  },
  related: {
    fontSize: "14px",
    mb: "0px",
    mr: "10px",
    textAlign: "left",
    color: "#628CE9",
    display: "inline-block",
    fontWeight: "bold",
  },
};

export default CaseStudySection;
